import opinions from '@amo/core/assets/images/icons/opinions_assurances.svg';
import lesfurets from '@amo/core/assets/images/logos/landing/100122.png';
import lelynx from '@amo/core/assets/images/logos/landing/101853.png';
import meilleureassurance from '@amo/core/assets/images/logos/landing/393798.png';
import assurland from '@amo/core/assets/images/logos/landing/67256.png';
import CodePromoContainer from '../CodePromo/CodePromoContainer';
import Franchises from '../Franchises/Franchises';
import Options from '../Options/Options';
import SouscrireContainer from '../Souscrire/SouscrireContainer';
import '../tarif.scss';
import getFormuleChoisie from '../utilsTarif/getFormuleChoisie';
import TableFormule from './TableFormule/TableFormule';
import FranchisesInfo from "../Franchises/FranchisesInfo";
import Calendar from "@amo/core/assets/images/AMO_Icons/Calendar.svg";
import { Question } from '@amo/core/components/containers';
import BlocAvis from "./BlocAvis/BlocAvis.jsx";
import {getTranslation, normalizeEuro} from "@amo/core/utils/index.js";
import {useEffect} from "react";
import indemnisation from '@amo/core/assets/images/icons/indemnisation.png';
import {SlideIndicator} from "@amo/core/components/containers/SlideIndicator/SlideIndicator.jsx";
import {BlocOptionPcc} from "@amo/core/components/group-form/BlocOption/BlocOptionPcc.jsx";
import {getIconOptions, getTextOptions} from "../Options/getTextOptions.js";
import _ from "lodash";
import {BlocOption} from "@amo/core/components/group-form/index.js";

const TableauGarantiesDesktop = (props) => {
    const {
        data, goTo, vehicule, formules, franchises, nameWhitelabel, apporteur2, IDDevis, apporteur1, nextQuestion,
        infoDTA, infoVI, prixTotalMensuelPromo, prixTotalAnnuelPromo, options, fractionnementChoisi, codePromo, changeValue
    } = props;
    const formuleChoisie = getFormuleChoisie(data, formules);
    const logos = { 67256: assurland, 100122: lesfurets, 101853: lelynx, 393798: meilleureassurance };

    const pccOption = options?.find((option) => option.idGroupe === 'PCC');

    useEffect(() => {
        document.getElementById('votre-tarif').getElementsByClassName('question')[0]?.classList.add('fade-in');
    }, []);

    return (
        <>
            {apporteur2 &&
                <div>
                    <div className={'row bg-grey'}>
                        <div className={`bg-blue rounded-bottom-medium p-5 header-landing position-relative`}>
                            <>
                                <p className={'text-white f-32 fw-bold mx-5 mt-5 mb-3 pt-lg-5'}>
                                    Vous êtes au meilleur endroit <br/>
                                    pour assurer votre <span className={'text-dark'}>
                                        {vehicule.brand?.name}
                                    </span>...{' '}
                                </p>
                                <div
                                    className={'px-4 py-3 bg-white mt-3 rounded position-absolute bloc-landing shadow'}
                                >
                                    {IDDevis && (
                                        <p className={'f-14 text-dark text-start fw-bold'}>
                                            Votre devis : <span className={'m-0 fw-bold'}>N°{IDDevis}</span>
                                        </p>
                                    )}
                                    <img src={logos[apporteur2.codeBelair]} alt="Logo" className={'pt-2'} width={100}/>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            }
            <div className={'bg-white col-12'}>
                <div className={'row'}>
                    <Question icon={indemnisation} title={getTranslation('pricing.iChooseMyPlan')}>
                        <p className={'f-18 m-3 m-auto'}
                           dangerouslySetInnerHTML={{__html: getTranslation('pricing.pricingDescription', {vehicule: vehicule.brand?.name})}}/>

                        {formules.length > 1 && <SlideIndicator className={'d-md-none'}/>}

                        <TableFormule formuleChoisie={formuleChoisie} {...props} />
                        <button
                            className={`btn btn-primary mt-5 w-100`}
                            onClick={() => nextQuestion()}
                            data-cy={'subscribe-button'}
                        >
                            <span className={'fw-bold'}
                                  dangerouslySetInnerHTML={{__html: getTranslation('pricing.iInsurerForMonth', {amount: normalizeEuro(formuleChoisie?.PrimeMensuelleBasePromo)})}}/>
                            <br/>
                            <small
                                className={'fw-light text-white'}>({getTranslation('pricing.iInsurerForYear', {amount: normalizeEuro(formuleChoisie?.PrimeAnnuelleFracAnnuel)})})</small>
                        </button>
                        <p className={'mb-5'}>
                            {apporteur1.codeBelair === '43397' && (
                                <img src={opinions} alt={'Opinions Assurances'}/>
                            )}
                        </p>
                    </Question>
                    <Question icon={Calendar} title={getTranslation('pricing.personalizeInsurance')}>
                        <div className="container mt-4">
                            <div className="row">
                                <div className="col-12">
                                    {pccOption && (
                                        <>
                                            <BlocOption
                                                idGroupe={pccOption.idGroupe}
                                                icon={getIconOptions(pccOption.idGroupe)}
                                                title={pccOption.labelGroupe}
                                                fractionnement={fractionnementChoisi}
                                                name={`options[${pccOption.idGroupe}]`}
                                                data={pccOption.data}
                                                changeValue={changeValue}
                                                selected={_.get(data, `options[${pccOption.idGroupe}]`,)}
                                                text={getTextOptions(pccOption.idGroupe, apporteur1.codeBelair)}
                                                codePromo={codePromo}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        {franchises.labelGroupe
                            ? <Franchises {...props} />
                            : (infoVI[0] && infoDTA[0] && (infoVI[0].Disponible !== 'NonDisponible' || infoDTA[0].Disponible !== 'NonDisponible')) &&
                            <FranchisesInfo {...props}
                                            VI={(infoVI[0] && infoVI[0].Disponible !== 'NonDisponible') ? infoVI[0] : null}
                                            DTA={(infoDTA[0] && infoDTA[0].Disponible !== 'NonDisponible') ? infoDTA[0] : null}
                            />
                        }
                        <button
                            className={`btn btn-primary mt-4 w-100`}
                            onClick={() => nextQuestion()}
                            data-cy={'subscribe-button'}
                        >
                            <span
                                className={'fw-bold'}
                                dangerouslySetInnerHTML={{__html: getTranslation('pricing.iInsurerForMonth', {amount: normalizeEuro(prixTotalMensuelPromo)})}}/><br/><small
                            className={'fw-light text-white'}>({getTranslation('pricing.iInsurerForYear', {amount: normalizeEuro(prixTotalAnnuelPromo)})})</small>
                        </button>
                    </Question>
                    <Question icon={Calendar} title={getTranslation('pricing.iChooseMyOptions')}>
                        <p className={'f-18 m-3 m-auto'}>{getTranslation('pricing.descriptionOptions', {brand: vehicule.brand?.name})}</p>
                        <Options formuleChoisie={formuleChoisie} {...props} />
                        <CodePromoContainer {...props} />
                        <SouscrireContainer formuleChoisie={formuleChoisie} {...props} />

                        <BlocAvis {...props} />
                    </Question>
                </div>
            </div>
        </>
    );
};

export default TableauGarantiesDesktop;
