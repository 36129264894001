import Permis from '@amo/core/assets/images/AMO_Icons/Permis.svg';
import InformationsBleu from '@amo/core/assets/images/icons/informations-bleu.png';
import warning from '@amo/core/assets/images/icons/warning.svg';
import { BulleAide, Question } from '@amo/core/components/containers';
import { DateField, Radio } from '@amo/core/components/forms';
import { showModal } from '@amo/core/utils/functions';
import { maxDateToday, permisA, permisA1, permisA2, permisAM, permisB } from '@amo/core/utils/validateField';
import { validateLicenseByVehicule } from '@amo/core/utils/validateLicense';
import _ from 'lodash';
import moment from 'moment';
import { Field } from 'redux-form';
import permis from '../../../../../../assets/images/bulle/permis.png';
import {getTranslation} from "@amo/core/utils/functions.js";

const ChoixPermis = (props) => {
    const { nextQuestion, data, changeValue, vehicule, errorsForm, goTo } = props;
    let text = '';

    if (
        !_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis][4][TypePermis]') &&
        Number(data?.cylindreeVehicule) > 125 &&
        _.includes(['moto', 'scooter'], data?.typeVehicule)
    ) {
        text = getTranslation("profil.licenseChoice.help.text");
    } else {
        text = getTranslation("profil.licenseChoice.help.textBis");
    }

    const isNotValid = () => {
        let notValid = false;
        _.forEach(_.get(data, `DemandeTarif[ListePersonnes][0][ListePermis]`), function (k, v) {
            if (!_.isEmpty(k)) {
                if (k !== undefined && k.TypePermis !== undefined && k.TypePermis !== null) {
                    if (
                        !_.get(data, `DemandeTarif[ListePersonnes][0][ListePermis][${v}][DatePermis]`) ||
                        !moment(
                            _.get(data, `DemandeTarif[ListePersonnes][0][ListePermis][${v}][DatePermis]`),
                            'DD/MM/YYYY',
                            true,
                        ).isValid() ||
                        !_.isEmpty(_.get(errorsForm, `DemandeTarif[ListePersonnes][0][ListePermis][${v}][DatePermis]`))
                    ) {
                        notValid = true;
                    }
                }
            }
        });
        return notValid;
    };
    let validLicense = validateLicenseByVehicule({
        permis: _.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'),
        vehicule,
        dateNaissance: _.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]'),
    });

    return (
        <Question icon={Permis} title={getTranslation("profil.licenseChoice.title")}>
            <div className={'row justify-content-center mt-2'}>
                <p
                    className={'f-12 text-secondary mb-3'}
                    onClick={() =>
                        showModal({
                            imageUrl: permis,
                            html: getTranslation("profil.licenseChoice.licenseImg"),
                        })
                    }
                >
                    <img src={InformationsBleu} alt="?" className={'align-middle'} />{' '}
                    <span className={'pb-1 cursor-pointer underline-link'}>
                        {getTranslation("profil.licenseChoice.whereFindLicense")}
                    </span>
                </p>
            </div>

            <BulleAide icon={warning} title={`${getTranslation("warning")} !`} text={text} className={'col-lg-12 col-md-12 my-4'} />

            <div className={'row justify-content-start mt-2'}>
                {Number(data?.cylindreeVehicule) <= 50 && (
                    <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                        <div>
                            <Field
                                component={Radio}
                                type={'checkbox'}
                                name={'DemandeTarif[ListePersonnes][0][ListePermis][0][TypePermis]'}
                                value={'AM'}
                                label={getTranslation("profil.licenseChoice.licenses.am")}
                                className={'small'}
                                normalize={(v) => (v ? 'AM' : null)}
                                onChange={() => changeValue('noPermis', null)}
                                dataCy={"permis-AM"}
                            />
                            {_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis][0][TypePermis]') && (

                                <div className={'bloc_permis'} data-cy={"date-permis-AM"}>
                                    <p className={'label-text mb-2'}>{getTranslation('profil.licenseChoice.obtainingDate')} </p>
                                    <Field
                                        name={`DemandeTarif[ListePersonnes][0][ListePermis][0][DatePermis]`}
                                        component={DateField}
                                        placeholder={getTranslation("placeholderDate")}
                                        validate={[maxDateToday, permisAM]}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {moment(_.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]'), 'DD/MM/YYYY').add(16, 'years') <
                    moment() && (
                    <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                        <div>
                            <Field
                                component={Radio}
                                type={'checkbox'}
                                name={'DemandeTarif[ListePersonnes][0][ListePermis][1][TypePermis]'}
                                value={'A1'}
                                label={getTranslation("profil.licenseChoice.licenses.a1")}
                                className={'small'}
                                normalize={(v) => (v ? 'A1' : null)}
                                onChange={() => changeValue('noPermis', null)}
                                dataCy={"permis-A1"}
                            />
                            {_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis][1][TypePermis]') && (
                                <div className={'bloc_permis'} data-cy={"date-permis-A1"}>
                                    <p className={'label-text mb-2'}>
                                        {getTranslation('profil.licenseChoice.obtainingDate')}
                                        <span
                                            className="d-inline-block btn-help ms-2"
                                            onClick={() =>
                                                showModal({
                                                    title: getTranslation("profil.licenseChoice.titleA1"),
                                                    html: getTranslation("profil.licenseChoice.htmlA1"),
                                                })
                                            }
                                        >
                                            ?
                                        </span>
                                    </p>
                                    <Field
                                        name={`DemandeTarif[ListePersonnes][0][ListePermis][1][DatePermis]`}
                                        component={DateField}
                                        placeholder={getTranslation("placeholderDate")}
                                        validate={[maxDateToday, permisA1]}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {moment(_.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]'), 'DD/MM/YYYY').add(18, 'years') <
                    moment() && (
                    <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                        <div>
                            <Field
                                component={Radio}
                                type={'checkbox'}
                                name={'DemandeTarif[ListePersonnes][0][ListePermis][2][TypePermis]'}
                                value={'A2'}
                                label={getTranslation('profil.licenseChoice.licenses.a2')}
                                className={'small'}
                                normalize={(v) => (v ? 'A2' : null)}
                                onChange={() => changeValue('noPermis', null)}
                                dataCy={"permis-A2"}
                            />
                            {_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis][2][TypePermis]') && (
                                <div className={'bloc_permis'} data-cy={"date-permis-A2"}>
                                    <p className={'label-text mb-2'}>
                                        {getTranslation('profil.licenseChoice.obtainingDate')}
                                        <span
                                            className="d-inline-block btn-help ms-2"
                                            onClick={() =>
                                                showModal({
                                                    title: getTranslation("profil.licenseChoice.titleA2"),
                                                    html: getTranslation("profil.licenseChoice.htmlA2"),
                                                })
                                            }
                                        >
                                            ?
                                        </span>
                                    </p>
                                    <Field
                                        name={`DemandeTarif[ListePersonnes][0][ListePermis][2][DatePermis]`}
                                        component={DateField}
                                        placeholder={getTranslation("placeholderDate")}
                                        validate={[maxDateToday, permisA2]}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {moment(_.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]'), 'DD/MM/YYYY').add(18, 'years') <
                    moment() && (
                    <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                        <div>
                            <Field
                                component={Radio}
                                type={'checkbox'}
                                name={'DemandeTarif[ListePersonnes][0][ListePermis][3][TypePermis]'}
                                value={'A'}
                                label={getTranslation('profil.licenseChoice.licenses.a')}
                                className={'small'}
                                normalize={(v) => (v ? 'A' : null)}
                                onChange={() => changeValue('noPermis', null)}
                                dataCy={"permis-A"}
                            />
                            {_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis][3][TypePermis]') && (
                                <div className={'bloc_permis'} data-cy={"date-permis-A"}>
                                    <p className={'label-text mb-2'}>
                                        {getTranslation('profil.licenseChoice.obtainingDate')}
                                        <span
                                            className="d-inline-block btn-help ms-2"
                                            onClick={() =>
                                                showModal({
                                                    title: getTranslation("profil.licenseChoice.titleA"),
                                                    html: getTranslation("profil.licenseChoice.htmlA")
                                                })
                                            }
                                        >
                                            ?
                                        </span>
                                    </p>
                                    <Field
                                        name={`DemandeTarif[ListePersonnes][0][ListePermis][3][DatePermis]`}
                                        component={DateField}
                                        placeholder={getTranslation("placeholderDate")}
                                        validate={[maxDateToday, permisA]}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {moment(_.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]'), 'DD/MM/YYYY').add(16, 'years') <
                    moment() && (
                    <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'}>
                        <div>
                            <Field
                                component={Radio}
                                type={'checkbox'}
                                name={'DemandeTarif[ListePersonnes][0][ListePermis][4][TypePermis]'}
                                value={'B'}
                                label={getTranslation('profil.licenseChoice.licenses.b')}
                                className={'small'}
                                normalize={(v) => (v ? 'B' : null)}
                                onChange={() => changeValue('noPermis', null)}
                                dataCy={"permis-B"}
                            />
                            {_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis][4][TypePermis]') && (
                                <div className={'bloc_permis'} data-cy={"date-permis-B"} >
                                    <p className={'label-text mb-2'}>
                                        {getTranslation('profil.licenseChoice.obtainingDate')}
                                        <span
                                            className="d-inline-block btn-help ms-2"
                                            onClick={() =>
                                                showModal({
                                                    title: getTranslation("profil.licenseChoice.titleB"),
                                                    html: getTranslation("profil.licenseChoice.htmlB"),
                                                })
                                            }
                                        >
                                            ?
                                        </span>
                                    </p>
                                    <Field
                                        name={`DemandeTarif[ListePersonnes][0][ListePermis][4][DatePermis]`}
                                        component={DateField}
                                        placeholder={getTranslation("placeholderDate")}
                                        validate={[maxDateToday, permisB]}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    )}

                {
                    /*
                        Les personnes nées à partir du 1er janvier 1988 doivent être titulaires du permis dans la catégorie "apprenti motard", AM, ex-BSR
                        Les personnes nées avant le 1er janvier 1988 sont dispensées de ces titres.
                    */
                    Number(data?.cylindreeVehicule) <= 50 &&
                        moment(_.get(data, 'DemandeTarif[ListePersonnes][0][DateNaissance]'), 'DD/MM/YYYY') <
                            moment('1988-01-01') && (
                            <div className={'col-lg-4 col-md-6 col-sm-12 col-12 my-2'} data-cy={"date-permis-noPermis"}>
                                <div>
                                    <Field
                                        component={Radio}
                                        type={'checkbox'}
                                        name={'noPermis'}
                                        value={'0'}
                                        label={getTranslation('profil.licenseChoice.licenses.noLicense')}
                                        className={'small'}
                                        normalize={(v) => (v ? '0' : null)}
                                        onChange={() =>
                                            data?.noPermis
                                                ? true
                                                : changeValue('DemandeTarif[ListePersonnes][0][ListePermis]', null)
                                        }
                                    />
                                </div>
                        </div>
                    )
                }
            </div>

            {!validLicense?.status && (
                <>
                    {((!_.isEmpty(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]')) &&
                        _.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (p) => {
                            return p?.TypePermis;
                        })) ||
                        _.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis][2][TypePermis]')) && (
                        <div className="alert alert-danger mt-3" role="alert">
                            {!_.isEmpty(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]')) &&
                                _.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (p) => {
                                    return p?.TypePermis;
                                }) && (
                                    <p className={'mb-0'}>
                                        {validLicense?.message || getTranslation('profil.licenseChoice.licenseIsNotAllow')}
                                        <br />
                                        <span
                                            className={'btn btn-info btn-sm f-12 cursor-pointer mt-2 text-light'}
                                            onClick={() => goTo({ step: 0, question: 1 })}
                                        >
                                            {getTranslation('profil.licenseChoice.updateVehicle')}
                                        </span>
                                    </p>
                                )}

                            {!_.isEmpty(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]')) &&
                                _.find(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis]'), (p) => {
                                    return p?.TypePermis;
                                }) &&
                                _.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis][2][TypePermis]') && <hr />}

                            {_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis][2][TypePermis]') && (
                                <p className={'mb-0'}>
                                    {validLicense?.message || getTranslation('profil.licenseChoice.onlyLicenseA2')}
                                </p>
                            )}
                        </div>
                    )}
                </>
            )}

            {validLicense?.status && validLicense?.message && (
                <div className="alert alert-warning mt-3" role="alert">
                    <p className="mb-0">{validLicense?.message}</p>
                </div>
            )}

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    ((_.isEmpty(_.get(data, `DemandeTarif[ListePersonnes][0][ListePermis]`)) &&
                        data?.noPermis !== '0' &&
                        data?.noPermis == null) ||
                        isNotValid() ||
                        !validLicense?.status) &&
                    'disabled'
                }`}
                type={'submit'}
                onClick={() => nextQuestion()}
            >
                {getTranslation('continue')}
            </button>
        </Question>
    );
};

export default ChoixPermis;
