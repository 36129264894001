import info from '@amo/core/assets/images/AMO_Icons/Searching.svg';
import { BulleAide, Question } from '@amo/core/components/containers';
import { LargeSpinner } from '@amo/core/components/loaders';
import { minDateHourToday } from '@amo/core/utils/validateField';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
    NumeroSerie,
    ImmatOuMarquage,
    Immatriculation
} from '@amo/core/components/questions';
import getFormuleChoisie from '../Tarif/utilsTarif/getFormuleChoisie';
import AncienAssureur from './AncienAssureur/AncienAssureur';
import DateHeureEffet from './DateHeureEffet/DateHeureEffet';
import DateHeureEffetHamon from './DateHeureEffetHamon/DateHeureEffetHamon';
import Iban from './Iban/Iban';
import LoiHamon from './LoiHamon/LoiHamon';
import NumeroContrat from './NumeroContrat/NumeroContrat';
import RecapPersonne from './RecapPersonne/RecapPersonne';
import TiersPayeurBirthCity from './TiersPayeur/TiersPayeurBirthCity/TiersPayeurBirthCity';
import TiersPayeurBirthCountry from './TiersPayeur/TiersPayeurBirthCountry/TiersPayeurBirthCountry';
import TiersPayeurBirthDate from './TiersPayeur/TiersPayeurBirthDate/TiersPayeurBirthDate';
import TiersPayeurBirthName from './TiersPayeur/TiersPayeurBirthName/TiersPayeurBirthName';
import TiersPayeurFirstName from './TiersPayeur/TiersPayeurFirstName/TiersPayeurFirstName';
import TiersPayeurLastName from './TiersPayeur/TiersPayeurLastName/TiersPayeurLastName';
import TiersPayeurRelationship from './TiersPayeur/TiersPayeurRelationship/TiersPayeurRelationship';
import TiersPayeurThirdPayerReason from './TiersPayeur/TiersPayeurThirdPayerReason/TiersPayeurThirdPayerReason';
import TiersPayeurType from './TiersPayeur/TiersPayeurType/TiersPayeurType';
import TitulaireCompte from './TitulaireCompte/TitulaireCompte';
import {Field} from "redux-form";
import {Checkbox} from "@amo/core/components/forms";
import {getIconByType, normalizeEuro, getTranslation, isAnonymized} from "@amo/core/utils";
import tchat from "@amo/core/assets/images/icons/FAQ.png";
import IbanDejaClient from "./IbanDejaClient/IbanDejaClient.jsx";

const Recapitulatif = (props) => {
    const {
        data,
        postPaySubmit,
        formules,
        payLoaded,
        nomTitulaireCg,
        nomPrenom,
        changeValue,
        isLoadedTarif,
        queryTarif,
        postReloadTarif,
        souscripteurIsSociety,
        analyticsSetEvent,
        nomSouscripteur,
        vehicule,
        goTo,
        pdfDevis,
        dispositionGenerale,
        documentInformation,
        lettreRGPD,
        isDejaClient
    } = props;
    const formuleChoisie = getFormuleChoisie(data, formules);
    const [tryPost, setTryPost] = useState(false);
    const isIbanDejaClient = (_.get(data, 'DemandeContrat[IBAN]') && isAnonymized(_.get(data, 'DemandeContrat[IBAN]')));
    const [immatDisabled, setImmatDisabled] = useState(_.get(data, 'DemandeTarif[Vehicule][Immatriculation]') && _.get(data, 'searchBy') === 'immatriculation');

    useEffect(() => {
        if (tryPost) {
            isNotValid();
        }
    }, [data, tryPost]);

    useEffect(() => {
        if(!isIbanDejaClient) {
            changeValue('DemandeContrat[NomTitulaire]', nomPrenom);
        } else {
            //Le demandecontrat n'est jamais reload car non stocké dans le devis.
            changeValue('DemandeContrat[NomTitulaire]', isDejaClient.DemandeContrat?.NomTitulaire);
            changeValue('DemandeContrat[IBAN]', isDejaClient.DemandeContrat?.IBAN);
            changeValue('DemandeContrat[BIC]', isDejaClient.DemandeContrat?.BIC);
        }
        if (!isLoadedTarif) {
            postReloadTarif({ form: data, query: queryTarif });
        }
    }, []);

    /* Function qui ajoute la class error sur les champs vide */
    const handleError = (fieldName, remove) => {
        _.forEach(document.getElementsByName(fieldName), (input) => {
            if (input.getAttribute('type') === 'radio') {
                if (remove) {
                    input.labels[0].classList.remove('error');
                } else {
                    input.labels[0].classList.add('error');
                }
            } else {
                if (remove) {
                    input.classList.remove('error');
                } else {
                    input.classList.add('error');
                }
            }
        });
    };

    const isNotValid = () => {
        let notValid = false;
        let fields = [
            `DemandeTarif[ListePersonnes][0][NumPermis]`,
            _.get(data, 'immatriculation_check') === '0'
                ? `DemandeTarif[Vehicule][VIN]`
                : `DemandeTarif[Vehicule][Immatriculation]`,
            `DemandeContrat[DevisHamon]`,
            `DemandeContrat[DateHeureEffet]`,
            `DemandeContrat[IBAN]`,
            `DemandeContrat[BIC]`,
        ];

        if (
            !isIbanDejaClient &&
            _.get(data, 'DemandeContrat[NomTitulaire]') &&
            _.toUpper(_.trim(_.get(data, 'DemandeContrat[NomTitulaire]'))) !== _.toUpper(nomTitulaireCg)
        ) {
            fields = [
                ...fields,
                `DemandeContrat[TiersPayeur][Type]`,
                `DemandeContrat[TiersPayeur][LastName]`,
                _.get(data, 'DemandeContrat[TiersPayeur][Type]') === 'P'
                    ? `DemandeContrat[TiersPayeur][BirthName]`
                    : '',
                _.get(data, 'DemandeContrat[TiersPayeur][Type]') === 'P'
                    ? `DemandeContrat[TiersPayeur][FirstName]`
                    : '',
                _.get(data, 'DemandeContrat[TiersPayeur][Type]') === 'P'
                    ? `DemandeContrat[TiersPayeur][BirthCountry]`
                    : '',
                _.get(data, 'DemandeContrat[TiersPayeur][Type]') === 'P'
                    ? `DemandeContrat[TiersPayeur][BirthCity]`
                    : '',
                _.get(data, 'DemandeContrat[TiersPayeur][Type]') === 'P'
                    ? `DemandeContrat[TiersPayeur][Relationship]`
                    : '',
                _.get(data, 'DemandeContrat[TiersPayeur][Type]') === 'P'
                    ? `DemandeContrat[TiersPayeur][BirthDate]`
                    : '',
                _.get(data, 'DemandeContrat[TiersPayeur][Type]') === 'P' &&
                _.get(data, 'DemandeContrat[TiersPayeur][Relationship]') === 'A'
                    ? `DemandeContrat[TiersPayeur][ThirdPayerReason]`
                    : ''
            ];
        }

        if (_.get(data, "DemandeContrat[DevisHamon]") === "1" && !_.get(data, "dont-know-contract-number")){
            fields = [...fields, 'DemandeContrat[DevisHamonContratActuel]']
        } else {
            handleError('DemandeContrat[DevisHamonContratActuel]', true)
        }

        if (_.get(data, "DemandeContrat[DevisHamon]") === "1"){
            fields = [...fields, 'confirm-souscripteur']
            fields = [...fields, 'DemandeContrat[DevisHamonAssureurActuel]']
        } else {
            handleError('confirm-souscripteur', true)
            handleError('DemandeContrat[DevisHamonAssureurActuel]', true)
        }

        fields.forEach((field) => {
            if (!_.get(data, field) && field !== '') {
                handleError(field);
                notValid = true;
            } else {
                handleError(field, true);
            }
            if (field === 'DemandeContrat[BIC]' && !_.get(data, field) && !isIbanDejaClient) {
                document.getElementById('btn-check-iban').style.color = 'red';
                document.getElementById('btn-check-iban').style.borderColor = 'red';
                document.getElementById('btn-check-iban').style.backgroundColor = 'transparent';
            }
        });

        if (
            !moment(_.get(data, 'DemandeContrat[DateHeureEffet]'), 'DD/MM/YYYY HH:mm', true).isValid() ||
            minDateHourToday(_.get(data, 'DemandeContrat[DateHeureEffet]'))
        ) {
            handleError('DemandeContrat[DateHeureEffet]');
            notValid = true;
        }

        return notValid;
    };

    const myPostPaySubmit = () => {
        setTryPost(true);
        if (isNotValid()) {
            document.getElementsByClassName('error')[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
            analyticsSetEvent({event: 'quoteConfirmation'})
            postPaySubmit();
        }
    };

    return (
        <>
            {isLoadedTarif ? (
                <>
                    {payLoaded ? (
                        <LargeSpinner message={getTranslation('summary.loading')} />
                    ) : (
                        <div id={'etape-recap'}>
                            <Question loader icon={info} title={'Récapitulatif'} full>
                                <div className="row justify-content-center">
                                    <div className="col-12 my-2 px-3 py-2 bg-white rounded border">
                                        <div className="row text-left">
                                            <div className="col-lg-8 col-md-7 col-sm-12">
                                                <p
                                                    className="card-title text-primary fw-bolder pt-3"
                                                    style={{textAlign: 'left'}}
                                                >
                                                    Mon assurance {formuleChoisie.LibelleFormule}
                                                </p>
                                            </div>
                                            <div className="col-lg-4 col-md-5 col-sm-12">
                                                <div className="d-flex justify-content-between">
                                                    <div className="col-lg-7 col-6 text-end">
                                                    <span className="f-26 fw-bold text-secondary">
                                                        {normalizeEuro(formuleChoisie.PrimeMensuellePromo)}
                                                    </span>
                                                    </div>
                                                    <div className="col-lg-5 col-6 fw-light mt-2 text-start ps-2">
                                                        <p className="mb-0 f-14">
                                                            / mois
                                                            <small className="f-10 d-block">
                                                                soit {normalizeEuro(formuleChoisie.ComptantAnnuelPromo)} par
                                                                an
                                                            </small>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 col-sm-12 border-end mb-3">
                                                <div className="row">
                                                    <div className="col-2">
                                                        <img
                                                            src={getIconByType(data?.typeVehicule)}
                                                            alt="Véhicule"
                                                            width={30}
                                                        />
                                                    </div>
                                                    <div className="col-10 text-start">
                                                        <p className="f-14 text-primary fw-bold">
                                                            Mon véhicule
                                                            <span
                                                                className="text-secondary f-12 float-end cursor-pointer underline-link"
                                                                onClick={() => goTo({step: 0, question: 1})}
                                                            >
                                                            modifier
                                                        </span>
                                                        </p>
                                                        <div className="f-13">
                                                            <p>
                                                                {vehicule.brand?.name} {vehicule.cylindree}
                                                            </p>
                                                            <p>{vehicule.modele}</p>
                                                            <p>
                                                                {vehicule.annee_debut_version && (
                                                                    <span>depuis {vehicule.annee_debut_version}</span>
                                                                )}
                                                                {vehicule.annee_fin_version && (
                                                                    <span> jusqu'à {vehicule.annee_fin_version}</span>
                                                                )}
                                                            </p>
                                                            <p>
                                                                Stationnement à{' '}
                                                                {_.get(data, 'DemandeTarif[Vehicule][VilleGarage]')}
                                                            </p>
                                                            {_.get(data, 'immatriculation_check') === '0' ? (
                                                                <NumeroSerie {...props} />
                                                            ) : (
                                                                <Immatriculation {...props} disabled={immatDisabled}
                                                                                 full/>
                                                            )}
                                                            <ImmatOuMarquage {...props} hasBulle/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <RecapPersonne {...props} />
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <div className="rounded px-4 pb-3">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <img
                                                                src={tchat}
                                                                alt="Documents"
                                                                width={30}
                                                            />
                                                        </div>
                                                        <div className="col-10">
                                                            <p className="f-14 text-primary fw-bold" style={{textAlign: 'left'}}>Mes documents à
                                                                télécharger</p>
                                                            <div className="d-block">
                                                                <a
                                                                    href={pdfDevis}
                                                                    target="_blank"
                                                                    download
                                                                    className="btn btn-light btn-pdf btn-block mb-2"
                                                                >
                                                                    Proposition d'assurance
                                                                </a>
                                                                <a
                                                                    href={dispositionGenerale}
                                                                    target="_blank"
                                                                    download
                                                                    className="btn btn-light btn-pdf btn-block mb-2"
                                                                >
                                                                    Dispositions Générales
                                                                </a>
                                                                <a
                                                                    href={documentInformation}
                                                                    target="_blank"
                                                                    download
                                                                    className="btn btn-light btn-pdf btn-block mb-2"
                                                                >
                                                                    Document d'information produit
                                                                </a>
                                                                <a
                                                                    href={lettreRGPD}
                                                                    target="_blank"
                                                                    download
                                                                    className="btn btn-light btn-pdf btn-block"
                                                                >
                                                                    Lettre RGPD
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {((_.get(data, 'DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]') > 11 ||
                                    _.includes(['50', 'quad', 'verte'], data?.typeVehicule)) && !souscripteurIsSociety && _.get(data, 'titulaireCarteGrise') !== "2") && (
                                    <>
                                        <p className="question-title mx-2 mt-5" data-title="Récapitulatif">{getTranslation('summary.hamonLaw')}</p>
                                        <LoiHamon {...props} />
                                    </>
                                )}

                                {((_.get(data, 'DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]') > 11 ||
                                            _.includes(['50', 'quad', 'verte'], data?.typeVehicule)) &&
                                        !souscripteurIsSociety) &&
                                    _.get(data, 'DemandeContrat[DevisHamon]') === '1' && (
                                        <>
                                            <div className="row justify-content-center">
                                                <div className={'col-12 col-lg-8 mb-2 mt-3'}>
                                                    <Field name="confirm-souscripteur" component={Checkbox}>
                                                        <p className={"cursor-pointer"}>{getTranslation('summary.certificate', {nom: nomSouscripteur})}<sup
                                                            className="text-danger">*</sup></p>
                                                    </Field>
                                                </div>
                                            </div>

                                            <AncienAssureur {...props} />

                                            <NumeroContrat {...props} />
                                        </>
                                    )}

                                {((_.get(data, 'DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]') > 11 ||
                                        _.includes(['50', 'quad', 'verte'], data?.typeVehicule)) &&
                                    !souscripteurIsSociety) && _.get(data, 'DemandeContrat[DevisHamon]') === '1' ? (
                                    <DateHeureEffetHamon {...props} formuleChoisie={formuleChoisie}/>
                                ) : (
                                    <DateHeureEffet {...props} formuleChoisie={formuleChoisie}/>
                                )}

                                <h3 className={'f-16 text-center fw-bold mt-4'}>{getTranslation('summary.levyInformations')}</h3>

                                <TitulaireCompte isIbanDejaClient={isIbanDejaClient} nomTitulaireCg={nomTitulaireCg} {...props} />

                                <Iban {...props} />

                                { isIbanDejaClient && <IbanDejaClient {...props} /> }

                                {!isIbanDejaClient && _.get(data, 'DemandeContrat[NomTitulaire]') &&
                                    _.toUpper(_.trim(_.get(data, 'DemandeContrat[NomTitulaire]'))) !==
                                    _.toUpper(nomTitulaireCg) && (
                                        <>
                                            <h3 className={'f-16 text-center fw-bold mt-5'}>{getTranslation('summary.payingPersonn')}</h3>

                                            <BulleAide
                                                icon={info}
                                                title={getTranslation('summary.help.title')}
                                                text={getTranslation('summary.help.text')}
                                                className={'mt-0 col-lg-8 offset-lg-2 col-md-12'}
                                            />

                                            <TiersPayeurType {...props} />

                                            <TiersPayeurLastName
                                                tiersPayeurType={_.get(data, 'DemandeContrat[TiersPayeur][Type]')}
                                                {...props}
                                            />

                                            {_.get(data, 'DemandeContrat[TiersPayeur][Type]') === 'P' && (
                                                <>
                                                    <TiersPayeurBirthName {...props} />

                                                    <TiersPayeurFirstName {...props} />

                                                    <TiersPayeurBirthDate {...props} />

                                                    <TiersPayeurBirthCountry {...props} />

                                                    <TiersPayeurBirthCity {...props} />

                                                    <TiersPayeurRelationship {...props} />

                                                    {_.get(data, 'DemandeContrat[TiersPayeur][Relationship]') ===
                                                        'A' && <TiersPayeurThirdPayerReason {...props} />}
                                                </>
                                            )}
                                        </>
                                    )}
                            </Question>

                            <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => myPostPaySubmit()}>
                                {getTranslation('subscribe')}
                            </button>
                        </div>
                    )}
                </>
            ) : (
                <LargeSpinner message={getTranslation('summary.loadingPrice')} />
            )}
        </>
    );
};

export default Recapitulatif;
