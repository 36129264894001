import { Text } from '@amo/core/components/forms';
import { checkIban } from '@amo/core/utils/requestsApi';
import _ from 'lodash';
import { useRef, useState } from 'react';
import { Field } from 'redux-form';
import {getTranslation} from "@amo/core/utils/index.js";
import {anonymizeString} from "@amo/core/utils/index.js";

const Iban = (props) => {
    const { changeValue, data, isIbanDejaClient, isDejaClient } = props;
    const inputIban = useRef('');
    const [errorIban, setErrorIban] = useState(null);
    const [loadingIban, setLoadingIban] = useState(false);

    const fetchCheckIban = async (value) => {
        setLoadingIban(true);
        let iban = await checkIban(value);
        setLoadingIban(false);
        changeValue('DemandeContrat[BIC]', iban.bank_data.bic);

        /* Vérifie si le format de l'iban est correct */
        const codeValidationsSuccess = ['001', '002', '003', '004', '005', '006', '007'];
        const fieldValidations = ['account', 'country_support', 'iban', 'length', 'structure'];
        let error = null;

        _.forEach(fieldValidations, (field) => {
            if (!_.includes(codeValidationsSuccess, iban.validations[field].code)) {
                error = iban.validations[field].message;
                return false;
            }
        });

        /* Vérifie si l'iban accepte les prélèvements */
        if (iban.sepa_data.SDD === 'NO' || iban.sepa_data.SCT === 'NO') {
            return setErrorIban(getTranslation('summary.iban.error'));
        }

        return error ? setErrorIban(getTranslation('summary.iban.errorFormat')) : setErrorIban(null);
    };

    return (
        <div className={'row justify-content-center'}>
            <div className={'col-lg-8 col-md-6 col-sm-12 col-12 mb-2'}>
                {
                    isIbanDejaClient ?
                        <Field
                            name={`ibanAnonymized`}
                            label={`IBAN`}
                            component={Text}
                            type={'text'}
                            typeFormat={'iban'}
                            input={{
                                value: anonymizeString(isDejaClient.DemandeContrat?.IBAN)
                            }}
                            disabled
                        />
                        : <Field
                            name={`DemandeContrat[IBAN]`}
                            label={`IBAN`}
                            component={Text}
                            type={'text'}
                            typeFormat={'iban'}
                            ref={inputIban}
                            disabled={Boolean(_.get(data, 'DemandeContrat[BIC]')) && !errorIban}
                            required
                        />
                }

            </div>
            <div className={'col-lg-8 col-md-6 col-sm-12 col-12 mb-2'}>
                {errorIban && <div className={'alert-danger alert'} dangerouslySetInnerHTML={{ __html: errorIban }} />}

                {Boolean(_.get(data, 'DemandeContrat[BIC]')) && (
                    <>
                        {
                            isIbanDejaClient ? <Field
                                    component={Text}
                                    name={'bicAnonymized'}
                                    label={`Code BIC`}
                                    normalize={(v) => v.toUpperCase()}
                                    input={{
                                        value: anonymizeString(isDejaClient.DemandeContrat?.BIC)
                                    }}
                                    disabled
                                />
                                : <Field
                                    component={Text}
                                    name={'DemandeContrat[BIC]'}
                                    normalize={(v) => v.toUpperCase()}
                                    label={`Code BIC`}
                                    disabled
                                />
                        }

                    </>
                )}

                {Boolean(_.get(data, 'DemandeContrat[BIC]')) && !errorIban ? (
                    <div className={'alert-success alert mb-0'} dangerouslySetInnerHTML={{__html: getTranslation('summary.iban.validate')}} />
                ) : (
                    <span
                        id="btn-check-iban"
                        className={'btn btn-secondary btn-smaller mt-2'}
                        onClick={loadingIban ? null : () => fetchCheckIban(inputIban.current.value)}
                    >
                        {loadingIban ? (
                            <div className="spinner-border" role="status" style={{ width: 19, height: 19 }}>
                                <span className="sr-only" />
                            </div>
                        ) : (
                            getTranslation('summary.iban.iCheckIban')
                        )}
                    </span>
                )}
            </div>
        </div>
    );
};

export default Iban;
